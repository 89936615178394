import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ethers } from "ethers";

import {
  contractAbi,
  binanceFaucetAddress,
  maticFaucetAddress,
} from "./contsant";
import Header from "./components/Header";
import SwitchWallet from "./components/SwitchWallet";
import { changedHandle, scrollToTop } from "./components/helperFunctions";
import TypingText from "./components/TypingText";

function App() {
  let [walletVersion, setWalletVersion] = useState(null);
  let [faucetAddress, setFaucetAddress] = useState(binanceFaucetAddress);
  const [check, setCheck] = useState(false);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [minting, setMinting] = useState(false);
  const ethereum = window.ethereum;

  async function connectWallet() {
    try {
      if (!ethereum || ethereum === "undefined")
        return toast.error("Install Wallet");
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setAddress(accounts[0]);
      toast.success(`Wallet Connected`);
    } catch (error) {
      console.error(error);
    }
  }

  async function disconnectWallet() {
    try {
      toast.dismiss();
      setAddress("");
      toast.success(`Wallet Disconnected`);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSwitchNetwork = async () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const { chainId } = await provider.getNetwork();
    if (chainId === 0x13881) {
      try {
        await ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x61" }],
          })
          .then(() => toast.success(`Network switched to bsc`));
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x61",
                  rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
                  chainName: "BSC Testnet",
                  nativeCurrency: {
                    name: "BNB",
                    symbol: "BNB",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://testnet.bscscan.com"],
                },
              ],
            });
          } catch (addError) {
            toast.error(addError.message);
          }
        }
      }
    } else if (chainId === 0x61) {
      try {
        await ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x13881" }],
          })
          .then(() => toast.success(`Network switched to polygon`));
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x13881",
                  rpcUrls: ["https://matic-mumbai.chainstacklabs.com/"],
                  chainName: "Polygon Testnet",
                  nativeCurrency: {
                    name: "MATIC",
                    symbol: "MATIC",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                },
              ],
            });
          } catch (addError) {
            toast.error(addError.message);
          }
        }
      }
    } else {
      try {
        await ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x13881" }],
          })
          .then(() => toast.success(`Network switched to polygon`));
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x13881",
                  rpcUrls: ["https://matic-mumbai.chainstacklabs.com/"],
                  chainName: "Polygon Testnet",
                  nativeCurrency: {
                    name: "MATIC",
                    symbol: "MATIC",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                },
              ],
            });
          } catch (addError) {
            toast.error(addError.message);
          }
        }
      }
    }
  };

  async function getActiveWallet() {
    try {
      if (!ethereum || ethereum === "undefined")
        return toast.error("Install Wallet");
      const accounts = await ethereum.request({
        method: "eth_accounts",
      });
      setAddress(accounts[0]);
      if (address) {
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getNetworkVersion() {
    if (!ethereum) return toast.error("Install Wallet");
    if (ethereum.networkVersion === "80001") {
      setCheck(true);
      setWalletVersion("80001");
      return setFaucetAddress(maticFaucetAddress);
    } else if (ethereum.networkVersion === "97") {
      setCheck(true);
      setWalletVersion("97");
      return setFaucetAddress(binanceFaucetAddress);
    } else {
      setCheck(false);
      setMinting(false);
      return toast.error(
        "Select BSC network Or Polygon network on your Wallet"
      );
    }
  }
  async function handleMint() {
    try {
      if (!ethereum) return toast.error("Install Wallet");
      if (!address) return toast.error("Connect Wallet");
      if (!(amount && currency)) return toast.error("Input is expected");
      else setMinting(true);
      if (check === true) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          faucetAddress,
          contractAbi,
          signer
        );
        const parsedAmount = ethers.utils.parseEther(amount);
        const transaction = await contract.disburseTokens(
          parsedAmount._hex,
          currency
        );

        await transaction.wait();
        toast.success("Transaction successfully");
        setMinting(false);
        setAmount("");
        setCurrency("");
      }
    } catch (err) {
      toast.error(`Transaction failed "${err?.data?.message}"`);
      setMinting(false);
    }
  }


  useEffect(() => {
    scrollToTop();
    changedHandle();
    getActiveWallet().then(() => {
      getNetworkVersion();
    });
    // eslint-disable-next-line
  }, []);

  return (
    <section
      className="bg-cover bg-no-repeat min-h-screen"
      style={{ backgroundImage: 'url("wallpaper.jpg")' }}
    >
      <div className="container px-6  mx-auto py-10 md:py-10 space-y-10 md:space-y-24">
        <Header
          address={address}
          connectWallet={connectWallet}
          disconnectWallet={disconnectWallet}
          handleSwitchNetwork={handleSwitchNetwork}
        />
        <div className=" flex flex-col justify-between gap-10 md:flex-row flex-wrap">
          <TypingText />
          <SwitchWallet
            amount={amount}
            setAmount={setAmount}
            currency={currency}
            walletVersion={walletVersion}
            setCurrency={setCurrency}
            minting={minting}
            handleMint={handleMint}
            faucetAddress={faucetAddress}
          />
        </div>
      </div>
      <div className="fixed bottom-0 hidden md:block p-0 md:p-8 m-0 md:m-10 bg-black rounded-lg">
        <h1 className="text-2xl font-semibold text-gradient">
          Supported networks
        </h1>
        <h5 className="text-white text-xl font-semibold">BSC (Testnet)</h5>
        <h5 className="text-white text-xl font-semibold">Polygon (Testnet)</h5>
      </div>
    </section>
  );
}

export default App;

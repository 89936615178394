import React from "react";
import { shortenAddress } from "./helperFunctions";
import Button from "./Reusables/Button";

function Header({
  address,
  disconnectWallet,
  connectWallet,
  handleSwitchNetwork,
}) {
  return (
    <div className="flex  flex-col md:flex-row justify-between md:items-center sticky">
      <div>
        <img src="/assets/logo (1).svg" alt="logo" className="w-40 md:w-60" />
      </div>
      <div className="flex flex-col gap-2 items-end">
        {address ? (
          <div className="flex gap-4  items-center">
            <Button
              text={shortenAddress(address)}
              onClick={handleSwitchNetwork}
              extraStyle={"w-40 p-2  text-sm md:w-60 md:text-lg md:p-4"}
              outlined={true}
            />{" "}
            <Button
              text={"Disconnect Wallet"}
              onClick={disconnectWallet}
              extraStyle={"w-40 p-2 text-sm md:w-60 md:text-lg md:p-4"}
            />
          </div>
        ) : (
          <Button
            text={"Connect Wallet"}
            onClick={connectWallet}
            extraStyle={"w-40 p-2 text-sm md:w-60 md:text-lg md:p-4"}
          />
        )}
      </div>
    </div>
  );
}

export default Header;

import React from "react";

function Button({ text, onClick, extraStyle, outlined }) {
  return (
    <>
      {!outlined ? (
        <button
          type="button"
          onClick={onClick}
          className={`bg-gradient p-4 rounded-full text-white  shadow-2xl font-semibold ${extraStyle}`}
        >
          {text}
        </button>
      ) : (
        <div className="bg-gradient p-1 items-center justify-center rounded-full">
          <button
            type="button"
            onClick={onClick}
            className={`bg-black  rounded-full text-white  shadow-2xl font-semibold ${extraStyle}`}
          >
            {text}
          </button>
        </div>
      )}
    </>
  );
}

export default Button;

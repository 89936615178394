import abi from "./TokenFaucet.json";

export const contractAbi = abi;

export const binanceFaucetAddress = "0x7039EfF0DdDEe82bF31b241dC8728F03997A4e1E";
export const maticFaucetAddress = "0x6C082B5eE95A0d23ecEf0Afdc1E0DF2cdD81baa5";

export const binanceCurrencies = [
    { name: "BUSD", address: "0x87e096350cDaeD7dadf702872Bc28F4b2D6421E6" },
    { name: "USDC", address: "0x5B46F3091F03758cB05E37c2CCCac275714aD14c" },
    { name: "USDT", address: "0xF0acaDDDa0cCD8871d09C69Cc166EE965e098e3E" },
    { name: "DAI", address: "0xb6F574EaF3FD1b26749A7be1C4169511802C57c2" },
    { name: "XEND", address: "0x03769388EC9F8D1643E18dcD07ca576737f6aDfF" },
    { name: "WNT", address: "0x0c739E4c1EbC570da141d9fb0C223cc644193dfe" }
];

export const maticCurrencies = [
    { name: "BUSD", address: "0xebE1Fb1B7ef5418cd1b7e0d27D4189aF45f66548" },
    { name: "USDC", address: "0xAB2e280f8f88dfC511D5eB88bA6Fac1ac8b21Cfd" },
    { name: "USDT", address: "0xE071280a687053be56E71aa777436b167b35C7fc" },
    { name: "DAI", address: "0xB89e48EF3143F60C05e5A68bc4edAD19f3979e6D" },
    { name: "XEND", address: "0x4646969e14618F3b96aF3A6E795C55d0903F1098" },
    { name: "WNT", address: "0x5B4dFbd122Ed33D26bc934BC699549fAbA5d3cdD" }
];
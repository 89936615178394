import { toast } from "react-toastify";

export function shortenAddress(address) {
  return `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;
}

export async function copyHandle(value) {
  try {
    await navigator.clipboard.writeText(value);
    toast.success("Copied");
  } catch (error) {
    toast.error("Failed");
  }
}

 export function changedHandle() {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => window.location.reload());
      window.ethereum.on("accountsChanged", () => window.location.reload());
    }
  }

 export function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
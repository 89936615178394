import React from "react";
import TypeWriterEffect from "react-typewriter-effect";
function TypingText() {

  return (
    <div className=" text-white text-xl md:text-3xl lg:text-4xl flex-1">
      <div className="container max-w-4xl mx-auto py-10 md:py-20 space-y-6 md:space-y-10 text-gray-600 overflow-wrap">
        <div className="flex justify-start items-center">
          <h1 className="text-white text-3xl md:text-5xl font-semibold text-gradient ">
            Service
          </h1>
        </div>
        <TypeWriterEffect
          textStyle={{ fontWeight: 500, color: "#fff" }}
          startDelay={100}
          cursorColor="#fff"
          text="SwitchWallet is an enterprise-grade wallet provision service. Leveraging our technology, you can securely process transactions in a fraction of the time while saving cost."
          typeSpeed={100}
          // scrollArea={myRef}
        />
      </div>
    </div>
  );
}

export default TypingText;

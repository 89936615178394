import React from "react";
import { binanceCurrencies, maticCurrencies } from "../contsant";
import { copyHandle, shortenAddress } from "./helperFunctions";
import Button from "./Reusables/Button";
function SwitchWallet({
  amount,
  setAmount,
  currency,
  setCurrency,
  minting,
  handleMint,
  faucetAddress,
}) {
  const amounts = [
    { value: 100 },
    { value: 200 },
    { value: 300 },
    { value: 1000 },
  ];

  const bscLink = "https://testnet.bscscan.com/address/";
  const polygonLink = "https://mumbai.polygonscan.com//address/";

  return (
    <div className="flex-1 mb-10">
      <div className="container max-w-7xl mx-auto md:py-20 space-y-10 md:space-y-16 text-gray-600">
        <div className="flex items-center justify-center">
          <h1 className="text-white text-3xl md:text-5xl font-semibold text-gradient">
            SwitchWallet Faucet
          </h1>
        </div>
        <div>
          <div className="focus:outline-none w-auto mx-auto mb-4 my-4 md:w-full shadow sm:px-10 sm:py-6 py-4 px-2 bg-gray-100 dark:bg-gray-800 rounded-md">
            <div>
              <div className="flex gap-4">
                <select
                  className="form-select w-full p-3 md:p-4 rounded-md focus:outline-none focus:border-violet-500 transition-colors cursor-pointer"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                >
                  <option value="" defaultValue disabled hidden>
                    Select amount
                  </option>
                  {amounts.map((number, i) => (
                    <option key={i}>{number.value}</option>
                  ))}
                </select>
                <select
                  className="form-select w-full p-3 md:p-4 rounded-md focus:outline-none focus:border-violet-500 transition-colors cursor-pointer"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option value="" defaultValue disabled hidden>
                    Select currency
                  </option>
                  {faucetAddress ===
                  "0x7039EfF0DdDEe82bF31b241dC8728F03997A4e1E"
                    ? binanceCurrencies.map((currency, i) => (
                        <option key={i} value={currency.address}>
                          {currency.name}
                        </option>
                      ))
                    : maticCurrencies.map((currency, i) => (
                        <option key={i} value={currency.address}>
                          {currency.name}
                        </option>
                      ))}
                </select>
              </div>
              <Button
                text={minting ? "Minting..." : "Mint"}
                onClick={handleMint}
                extraStyle={"w-full p-2 mt-4 text-sm  md:text-lg md:p-4 mb-4"}
              />
            </div>
            <div className="flex justify-between flex-col md:flex-row gap-10">
              <div>
                <h1 className=" md:text-3xl   text-2xl  font-bold">BSC</h1>
                {binanceCurrencies.map((currency, i) => (
                  <div
                    key={i}
                    className="text-xs md:text-sm flex my-2 md:my-3 items-center gap-4 cursor-pointer hover:translate-x-2 hover:scale-105 hover:shadow-lg hover:transition-transform ease-in-out duration-75 text-gray-500 rounded shadow-md p-4"
                  >
                    <span className="text-lg font-semibold">
                      {currency.name}
                    </span>
                    {shortenAddress(currency.address)}
                    <svg
                      className="cursor-pointer h-6 w-6"
                      onClick={() => copyHandle(bscLink + currency.address)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                      />
                    </svg>
                  </div>
                ))}
              </div>
              <div>
                <h1 className=" md:text-3xl   text-2xl  font-bold">Polygon</h1>
                {maticCurrencies.map((currency, i) => (
                  <div
                    key={i}
                    className="text-xs md:text-sm flex my-2 md:my-3 items-center gap-4 text-gray-500 rounded shadow-md p-4 cursor-pointer hover:translate-x-2 hover:scale-105 hover:shadow-lg hover:transition-transform ease-in-out duration-75"
                  >
                    <span className="text-lg font-semibold">
                      {currency.name}
                    </span>
                    {shortenAddress(currency.address)}
                    <svg
                      className="cursor-pointer h-6 w-6"
                      onClick={() => copyHandle(polygonLink + currency.address)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwitchWallet;
